.app-auth {
  padding: 10px 16px;
  background: #F1F4F6;
  display: flex;
  flex: 1;
}

.auth-register {
  background: #FFFFFF;
  border-radius: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  margin-bottom: 0px;

  .bg-top {
    width: 100%;
    height: auto;
    margin-bottom: 12px;

    svg {
      width: 100%;
      height: auto;
    }
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
  }

  .controls {
    padding: 16px;
    width: 100%;
  }

  .control {
    margin-bottom: 5px;
  }

  .button-register {
    margin-top: 5px;
  }
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: none;
}

.in-register .PhoneInputCountryStyle {
  border: 1px solid #ccd3d3;
  border-radius: 16px;
}

.in-register {
  margin-top: 16px;
}
