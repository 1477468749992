.app-archive {
  background: #F1F4F6;
  flex: 1;
  padding: 16px;
  width: 100%;
}

button.start-consult {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 6px 12px;
  width: 59px;
  height: 32px;
  right: 0px;
  background: #4DBAAB;
  border-radius: 10px;
  border: none;
}

.view-bg {
  background-image: url("../../assets/images/bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.none-after:after {
  display: none;
}

.none-after:before {
  content: '';
  position: absolute;
  top: 61px;
  left: -5px;
  width: 5px;
  border-radius: 5px;
  border: 3px solid white;
  background-color: lightgray;
  z-index: 3;
  height: 5px;
}

.box-answer {
  border: 1px solid #f3f7fc;
  margin-bottom: 20px;
  border-radius: 8px;
}

.box-answer-content {
  padding: 20px;
}

label.lb-a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  font-family: 'Bebeboo-Regular';
}

label.lb-a > input {
  margin-right: 7px;
}