.app-consult, .scrollbar-container.cs-message-list__scroll-wrapper.ps {
  padding: 16px;
  width: 100%;
  background-image: url("../../assets/images/bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.cs-main-container {
  border: none !important;
  margin-left: -16px;
  margin-right: -16px;
  flex: 1;
  height: 100%;
}

.chat-container {
  height: calc(100% - 20px);
  padding-top: 10px;
  position: relative;
}

.chat-consult {
  height: 100%;
}

.cs-message-input {
  height: 70px;
  padding: 10px 16px !important;
  border: none !important;
  background: #F1F4F6 !important;
  margin-top: 20px !important;
  position: absolute !important;
  width: 100%;
  bottom: 0;
  left: 0;
}

.cs-message-input__content-editor-wrapper {
  background-color: #fff !important;
  border-radius: 25px !important;
  margin: 0 !important;
}

.cs-message-separator {
  background: none;
}

.cs-message-input__content-editor-container {
  background-color: white !important;
}

.cs-message-input__content-editor {
  background-color: white !important;
  height: 30px !important;
  line-height: 30px !important;
}

.cs-message-input__tools {
  padding: 0;
  position: absolute;
  width: 40px;
  height: 40px;
  right: 24px;
  background: #4DBAAB;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 16px;
}

button.cs-button.cs-button--send {
  color: white !important;
  opacity: 1;
  padding: 0;
  margin: 0;
  height: 20px;
  width: 20px;
  display: contents;
  line-height: initial;
}

.cs-message__content {
  background: #F3F7FC !important;
}

.cs-message__html-content {
  font-size: 18px !important;
  line-height: 24px !important;
  color: #3A3B56 !important;
  font-family: 'Bebeboo-Regular', sans-serif !important;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}

.cs-message-separator:before, .cs-message-separator:after {
  display: none !important;
}

.cs-message-separator {
  display: block !important;
  margin-bottom: 10px !important;
  text-align: center !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #6A6A6A !important;
}

.popup-call {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
  background-color: white;
}

strong.name-chat {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #4DBAAB;
}

span.link-copy {
  position: fixed;
  top: 80px;
  z-index: 099;
  left: calc(50% - 51px);
  padding: 10px 20px;
  background-color: #4dbaab;
  color: white;
  border-radius: 30px;
  font-family: 'Bebeboo-Regular';
  font-size: 15px;
}

.text-input {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #f1f4f6;
  padding: 0 13px;
  /* border-radius: 20px; */
}

.text-input > input {
  height: 50px;
  border: none;
  font-size: 16px;
  font-family: 'Bebeboo-Regular';
  display: flex;
  width: 100%;
  border-radius: 50px;
  padding: 0 50px 0 15px;
}

.text-input svg {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 20px;
  top: calc(50% - 20px);
}

.cs-message-separator {
  background-color: transparent !important;
}

.cs-message__sent-time {
  font-family: 'Bebeboo-Regular';
  color: #bfbfbf !important;
}

span.new-message {
  display: block;
  position: fixed;
  bottom: 88px;
  z-index: 100;
  width: 200px;
  background-color: white;
  box-shadow: 0 0 4px #0000004d;
  left: calc(50% - 100px);
  text-align: center;
  padding: 9px 10px;
  border-radius: 20px;
  color: #00bcd4;
}
