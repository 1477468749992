.app-home {
  padding: 16px;
  background: #F1F4F6;
  display: flex;
  flex: 1;
}

.home {
  background: #FFFFFF;
  border-radius: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  margin-bottom: 20px;

  .bg-top {
    width: 100%;

    svg {
      width: 100%;
    }
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 26px;
  }
}

a.logout {
  position: absolute;
  left: calc(50% - 20px);
  bottom: 20px;
  color: #f44336d4;
  font-size: 17px;
  font-family: 'Bebeboo-SemiBold';
}