@import "assets/css/reset.css";

@font-face {
    font-family: 'Bebeboo-SemiBold';
    src: local('Bebeboo-SemiBold'), url(./assets/fonts/Bebeboo/Bebeboo-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Bebeboo-Regular';
    src: local('Bebeboo-SemiBold'), url(./assets/fonts/Bebeboo/Bebeboo-Regular.ttf) format('truetype');
}

html, body {
    margin: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    flex: 1;
    min-height: 100%;
    background-color: #f1f4f6;
}

* {
    box-sizing: border-box;
    font-family: Bebeboo-SemiBold, Bebeboo-Regular, sans-serif;
}

input {
    -webkit-appearance: none;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

#root {
    display: flex;
    width: 100%;
    flex-direction: column;
    min-height: 100%;
    flex: 1;
    max-width: 500px;
    margin: 0 auto;
}

input, select {
    outline: none !important;
}

.text-center {
    text-align: center;
}

.center {
    margin-left: auto;
    margin-right: auto;
}

.two-col > div {
    width: 48%;
}

* {
    outline: none !important;
}

.app > div {
    width: 100%;
}

.app > div > div {
    min-height: 100%;
}

*::-webkit-scrollbar {
    width: 0;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
*::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0);
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
    background: rgba(85, 85, 85, 0);
}

.my-enter {
    transition: 0.2s all;
    transform: translateX(50%);
}

/*.my-active-exit {*/
/*    transform: translateX(-100%);*/
/*}*/

.my-don-exit {
    display: none;
}

.my-active-enter {
    transform: translateX(0%);
}

.my-done-enter {
    transform: none;
}

span.no-data {
    font-size: 16px;
    color: #F55046;
}

.loading {
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
    transition: all 0.2s;
    align-items: center;
    justify-content: center;
    background-color: #ffffffc4;
}

.loading > img {
    width: 50px;
}

.loading.load {
    display: flex;
}