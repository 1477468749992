.app-call {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

@media only screen and (min-width: 600px) {
  .popup-call {
    max-width: 500px;
    margin: 0 auto;
    left: calc(50% - 250px);
  }
}
