.PhoneInputCountryStyle {
  background: #FFFFFF;
  border: 1px solid #E9EEF6;
  box-sizing: border-box;
  border-radius: 30px;
  order: 1;
  flex-grow: 0;
  margin: 4px 0px;
  height: 48px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.PhoneInputCountry {
  background: #E9EEF6;
  height: 48px;
  width: 100px;
  margin: 0;

  * {
    box-shadow: none !important;
  }
}

.PhoneInputCountryIcon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100px;
  background: none;
  box-shadow: none;
}

.PhoneInputCountryIconImg {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  margin: 0 11px;
}

span.PhoneInputCountryCode {
  font-size: 18px;
  line-height: 24px;
  color: #3A3B56;
  font-family: Bebeboo-Regular, sans-serif;
}

input.PhoneInputCountryInput {
  flex: 1;
  padding-left: 10px;
  height: 48px;
  border: none;
  font-size: 18px;
  line-height: 24px;
  color: #3A3B56;
  font-family: Bebeboo-Regular, sans-serif;
}
